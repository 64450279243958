import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from '../common/hooks/useauth_hook';

const AuthProtected = (props) => {
  const { loginObj, tokenObj } = useAuth();
  let tokenExpirationTime = tokenObj?.expiryDate;

  if (!loginObj.isAuthenticated || !tokenExpirationTime || new Date(tokenExpirationTime).getTime() < new Date().getTime()) {
    return (
      <Navigate to={{ pathname: "/login" }} />
    );
  }
  return (<React.Fragment>{props.children}</React.Fragment>);
};

export default AuthProtected;
