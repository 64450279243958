import {RootReducer} from './reducers/root_reducer';
import thunk from "redux-thunk";
import DomainMiddleware from "../middlewares/domain_middleware";
import AuthMiddleware from "../middlewares/auth_middleware";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore,persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';
import persistConfig from '../../helpers/PersistConfig';

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }),
  });
  const persistor = persistStore(store);

  export { store, persistor };

