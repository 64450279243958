import React from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "slices/auth/login/thunk";

const AnonymousRoute = (props) => {
  const dispatch: any = useDispatch();
  dispatch(logoutUser());
  return (<React.Fragment>{props.children}</React.Fragment>);
};

export default AnonymousRoute;
