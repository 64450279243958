import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    revenue: {},
    profitloss:{},
    netIncomeDetails:{},
    propertyIncomeSummary:{},
    error: "",// for error msg
    errorMsg: false,// for error
    selectOwnerOption: {}
};

const AppSlice = createSlice({
    name: "App",
    initialState,
    reducers: {
        GetUserRentalRevenueSuccess(state, action) {
            state.revenue = action.payload
            state.errorMsg = false;
        },
        GetOwnerFinancialSummarySuccess(state, action) {
            state.selectOwnerOption = action.payload
            state.errorMsg = false;
        },
        GetOwnerRentalProfitLossSuccess(state, action) {
            state.profitloss = action.payload
            state.errorMsg = false;
        },
        GetOwnerNetIncomeDetailsSuccess(state, action) {
            state.netIncomeDetails = action.payload
            state.errorMsg = false;
        },
        GetPropertyIncomeSummarySuccess(state, action) {
            state.propertyIncomeSummary = action.payload
            state.errorMsg = false;
        },
        Error(state, action) {
            state.error = action.payload.payload;
            state.errorMsg = true;
        }
    }
});

export const { GetUserRentalRevenueSuccess, 
    GetPropertyIncomeSummarySuccess,
    GetOwnerFinancialSummarySuccess, 
    GetOwnerRentalProfitLossSuccess, 
    GetOwnerNetIncomeDetailsSuccess,
    Error 
} = AppSlice.actions;
export default AppSlice.reducer;