import ServiceInstance from "../../services/api_service";
import { GetOwnerSuccess, UpdateOwnerSelectOptionSuccess, Error } from "../reducers/global_reducer";
import _var from "../../../helpers/var";

export const GetOwners = () => async (dispatch: any) => {
  try {
    let response: any = await ServiceInstance.FetchData(`${_var.main_api_url_v2}/user/owner`);
    dispatch(GetOwnerSuccess(response));
  } catch (error: any) {
    dispatch(Error("error"));
  }
}
export const UpdateOwnerSelectedOption = (data: any) => (dispatch: any) => {
  dispatch(UpdateOwnerSelectOptionSuccess(data));
}
