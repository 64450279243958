import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    owner: [],
    error: "",// for error msg
    errorMsg: false,// for error
};

const SysSlice = createSlice({
    name: "System",
    initialState,
    reducers: {
        GetOwnerSuccess(state, action) {
            state.owner = action.payload
            state.errorMsg = false;
        },
        Error(state, action) {
            state.error = action.payload.payload;
            state.errorMsg = true;
        }
    }
});
export const { GetOwnerSuccess, Error} = SysSlice.actions;
export default SysSlice.reducer;