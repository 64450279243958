
import { USER_LOGIN,USER_LOGOUT } from '../actiontype';

const initialState:any = {
  isAuthenticated: false,
  loginData:{},
};

const AuthReducer = (state = initialState, action:any) => {
  let { type, payload} = action;

  switch (type) {
      case USER_LOGIN:
        return {
          ...state,
          isAuthenticated: true,
          loginData: payload,
        };
    case USER_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
