import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import {utils} from "../../helpers/utils";


const WelComeback = ({data}: any) => {
  const {Claims} = utils.DecodeToken();
  const firstName = utils.FilterArray(Claims, (x: any) => x.ClaimType === 'FirstName')[0].ClaimValue
  const lastName = utils.FilterArray(Claims, (x: any) => x.ClaimType === 'LastName')[0].ClaimValue
  const proImage = utils.FilterArray(Claims, (x: any) => x.ClaimType === 'ProfImagePath')[0].ClaimValue

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row>
            <Col xs={7}>
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Cozaah Dashboard</p>
              </div>
            </Col>
            <Col xs={5} className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm={4}>
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={proImage}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{firstName} {lastName}</h5>
            </Col>

            <Col sm={8}>
              <div className="pt-4">
                <Row>
                  <Col xs={6}>
                    <h5 className="font-size-15">{data?.refCount ?? 0}</h5>
                    <p className="text-muted mb-0">Rents</p>
                  </Col>
                  <Col xs={6}>
                    <h5 className="font-size-15">{ data?.revenue ?? 0}</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelComeback
