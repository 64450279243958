import { useSelector } from 'react-redux';

interface TokenObj {
  token: string,
  expiryDate: string,
  tenantGuid: string,
  tenantId: string,
}

const useAuth = () => {
  const loginObj = useSelector((state:any) => state.Login);
  const tokenObj : TokenObj = loginObj.user;

  return {
    loginObj,
    tokenObj,
  };
};

export default useAuth;