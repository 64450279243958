import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import WelComeback from './WelComeback';
import LatestTransaction from "./LatestTransaction";
import MiniWidget from './mini-widgets';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from 'Components/Common/Breadcrumb';
import { utils } from "../../helpers/utils";
import { GetOwnerRentalProfitLoss, GetUserRentalRevenue, GetPropertyIncomeSummary } from "../../common/redux/actions/app_action";
import Doughnut from "../Allcharts/echart/doughnutchart"
import Pie from "../Allcharts/echart/piechart"
import LineBar from "../Allcharts/echart/linebarchart"
import Gauge from "../Allcharts/echart/gaugechart"

const Dashboard = () => {
  document.title = "Dashboards | Cozaah - React Admin & Dashboard Template";

  const dispatch = useDispatch<any>();
  const { CurrentTenantId, CurrentUserId } = utils.DecodeToken();
  const resp = useSelector((state: any) => state.global);
  const appObj = useSelector((state: any) => state.app);
  const expense = utils.ExtractDecimalFromString((utils.FilterArray(appObj?.profitloss?.data ?? [], (x: any) => x.id === "3")[0])?.value ?? "0.0");
  const income = utils.ExtractDecimalFromString((utils.FilterArray(appObj?.profitloss?.data ?? [], (x: any) => x.id === "2")[0])?.value ?? "0.0");
  let expenseIncomeRatio: number = Math.round((expense * 100) / income);

  console.log('global', resp)
  console.log('app', appObj)

  useEffect(() => {
    dispatch(GetOwnerRentalProfitLoss(CurrentTenantId, resp?.selectOwnerOption?.value ?? "",
      utils.BeginningOfYear(), (new Date()).toDateString()));

    dispatch(GetUserRentalRevenue(CurrentTenantId, CurrentUserId, utils.BeginningOfYear(), (new Date()).toDateString()));

    dispatch(GetPropertyIncomeSummary(CurrentTenantId, resp?.selectOwnerOption?.value ?? "",
      utils.BeginningOfYear(), (new Date()).toDateString()));
  }, [dispatch, resp]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboards" breadcrumbItem="Dashboard" />
          <Row>
            <Col xl={4}>
              <WelComeback data={appObj?.revenue?.data} />
              <Card>
                <CardBody>
                  <CardTitle>YTD Expense Income Ratio</CardTitle>
                  <div id="gauge-chart" className="e-chart">
                    <Gauge dataColors='["--bs-success","--bs-primary","--bs-danger"]' data={expenseIncomeRatio ? expenseIncomeRatio : 0} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={8}>
              <Row>
                <MiniWidget data={appObj?.profitloss?.data} />
              </Row>

              <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle>Shows revenue and expense with netcome</CardTitle>
                    <div id="mix-line-bar" className="e-chart">
                      <LineBar dataColors='["--bs-success","--bs-primary", "--bs-danger"]' />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>

          </Row>
          <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  <CardTitle>Revenue By Properties</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Pie dataName={appObj?.propertyIncomeSummary?.data?.incomeName|| ""} 
                      dataValue={appObj?.propertyIncomeSummary?.data?.revenue || ""}
                      dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]' />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  <CardTitle>Expense By Properties</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <Doughnut dataName={appObj?.propertyIncomeSummary?.data?.expenseName|| ""} 
                      dataValue={appObj?.propertyIncomeSummary?.data?.expense || ""}
                      dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]' />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <LatestTransaction />
            </Col>
          </Row>
        </Container>
      </div >

    </React.Fragment >
  );
}

export default Dashboard;