import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    owner: [],
    error: "",// for error msg
    errorMsg: false,// for error
    selectOwnerOption:{}
};

const GlobalSlice = createSlice({
    name: "Global",
    initialState,
    reducers: {
        GetOwnerSuccess(state, action) {
            state.owner = action.payload
            state.errorMsg = false;
        },
        UpdateOwnerSelectOptionSuccess(state, action) {
          state.selectOwnerOption = action.payload
      },
        Error(state, action) {
            state.error = action.payload.payload;
            state.errorMsg = true;
        }
    }
});
export const { GetOwnerSuccess, UpdateOwnerSelectOptionSuccess, Error} = GlobalSlice.actions;
export default GlobalSlice.reducer;