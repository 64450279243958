import { SET_DOMAIN_NAME } from '../actiontype';

const initialState:any  = {
  domainName: '',
};

const domainreducer = (state = initialState, action:any) => {
  let { type } = action;
  switch (type) {
    case SET_DOMAIN_NAME:
      return {
        ...state,
        domainName: action.payload,
      };
    default:
      return state;
  }
};

export default domainreducer;