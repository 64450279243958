import React, { useState, useMemo, useCallback, useEffect } from "react";
import withRouter from "../../Components/Common/withRouter";

import { Badge, Button, Card, CardBody, CardTitle, } from "reactstrap";
import EcommerenceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import { utils } from "../../helpers/utils";
import TableContainer from "../../Components/Common/TableContainer";
import { getTransaction } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { LatestTransactions } from "./type";
import { Link } from "react-router-dom";

interface selectState {
  dashboard: {
    dashboardTransaction: {
      data: {
        tableProps: LatestTransactions[]
        loading: boolean;
        recentTrans:any;
      }
    }
  };
};

const LatestTransaction: React.FC = () => {
  const resp = useSelector((state: any) => state.global);

  const dispatch = useDispatch<any>();
  const { CurrentTenantId } = utils.DecodeToken();
  const selectProperties = createSelector(
    (state: selectState) => state.dashboard,
    (dashboard) => ({
      latestTransaction: dashboard.dashboardTransaction
    })
  );

  const { latestTransaction } = useSelector(selectProperties);
    console.log('latestTransaction',latestTransaction?.data?.recentTrans || []);
  const [modal1, setModal1] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<any>('');
  const toggleViewModal = useCallback((value: any) => { setModal1(!modal1); setEditDetails(value) }, [modal1]);

   const columns = useMemo(
    () => [
      {
        header: "Property",
        id:"#",
        accessorKey: "propertyImg",
        cell: (cellProps: any) => (
            <img src={cellProps.row.original.propertyImg} alt="" className="avatar-md h-auto d-block rounded" />
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Property Name",
        accessorKey: "propertyName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Payer",
        accessorKey: "payerName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return <Badge className={"font-size-11 badge-soft-" +
            (cellProps.row.original.statusId === "SYS-COD-10180" ? "success" : "danger" || cellProps.row.original.statusId === "SYS-COD-10150" 
            || cellProps.row.original.statusId === "SYS-COD-10149" ? "warning" : "danger")}
          >
            {cellProps.row.original.statusName}
          </Badge>;
        },
      },
      {
        header: "Due Date",
        accessorKey: "dueDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "View Details",
        accessorKey: "view",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <Button type="button" color="primary" className="btn-sm btn-rounded" onClick={() => toggleViewModal(cellProps.cell.row.original)}  > View Details </Button>
          );
        },
      },
      
    ],
   
    [latestTransaction?.data?.tableProps || [],toggleViewModal]
  ); 

  useEffect(() => {
    dispatch(getTransaction({ userId: resp.selectOwnerOption.value, tenantId: CurrentTenantId, fromDate: utils.BeginningOfYear(), toDate: (new Date()).toDateString() }));
  }, [dispatch,resp])


  return (
    <React.Fragment>
      <EcommerenceOrdersModal isOpen={modal1} toggle={toggleViewModal} editDetails={editDetails} />
      <Card>
        <CardBody>
          <CardTitle tag="h4" className="mb-4">Latest Transaction</CardTitle>
          <TableContainer
            columns={columns}
            data={latestTransaction?.data?.recentTrans || []}
            isGlobalFilter={false}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};


export default withRouter(LatestTransaction);
