import React , { useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../Components/Common/ChartDynamicColor";
import { utils } from "../../../helpers/utils";
import { GetOwnerNetIncomeDetails } from "../../../common/redux/actions/app_action";
import { useSelector, useDispatch } from 'react-redux';

const LineBar = ({dataColors}:any) => {
  const dispatch = useDispatch<any>();
  const { CurrentTenantId, CurrentUserId } = utils.DecodeToken();
  const resp = useSelector((state: any) => state.global);
  const appObj = useSelector((state: any) => state.app);
  const spineareaChartColors = getChartColorsArray(dataColors);


  useEffect(() => {
    dispatch(GetOwnerNetIncomeDetails(CurrentTenantId, resp?.selectOwnerOption?.value, utils.BeginningOfYear(), (new Date()).toDateString()));
  }, [dispatch, resp]);

  const options = {
    grid: {
      zlevel: 0,
      x: 80,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    toolbox: {
      orient: "center",
      left: 0,
      top: 20,
      feature: {
        dataView: { show: true, readOnly: false, title: "Data View" },
        magicType: { show: true, type: ["line", "bar"], title: { line: "For line chart", bar: "For bar chart" } },
        restore: { show: true, title: "restore" },
        saveAsImage: { show: true, title: "Download Image" },
      },
    },
    color: spineareaChartColors,
    legend: {
      data: ["Revenue", "Expense", "Income"],
      textStyle: {
        color: ["#8791af"],
      },
    },
    xAxis: [
      {
        type: "category",
        data: appObj?.netIncomeDetails?.data?.months || [],
        axisPointer: {
          type: "shadow",
        },
        axisLine: {
          lineStyle: {
            color: "#8791af",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "Income",
        min: 0,
        max: appObj?.netIncomeDetails?.data?.maxIncome,
        interval: appObj?.netIncomeDetails?.data?.maxIncome * 0.1,
        axisLine: {
          lineStyle: {
            color: '#8791af'
          },
        },
        splitLine: {
          lineStyle: {
            color: "rgba(166, 176, 207, 0.1)"
          }
        },
        axisLabel: {
          formatter: '${value}'
        }
      },
      {
        type: "value",
        name: "Income",
        min: 0,
        max: appObj?.netIncomeDetails?.data?.maxIncome,
        interval: appObj?.netIncomeDetails?.data?.maxIncome * 0.1,
        axisLine: {
          lineStyle: {
            color: '#8791af'
          },
        },
        splitLine: {
          lineStyle: {
            color: "rgba(166, 176, 207, 0.1)"
          }
        },
        axisLabel: {
          formatter: '${value}'
        }
      },
    ],
    series: [
      {
        name: "Revenue",
        type: "bar",
        data: appObj?.netIncomeDetails?.data?.revenue || [],
      },
      {
        name: "Expense",
        type: "bar",
        data: appObj?.netIncomeDetails?.data?.expense || [],
      },
      {
        name: "Income",
        type: "line",
        yAxisIndex: 1,
        data: appObj?.netIncomeDetails?.data?.income || [],
      },
    ],
    textStyle: {
      color: ["#74788d"],
    },  
  }


  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};

export default LineBar;
