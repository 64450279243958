import axios from 'axios';
import _var from '../../helpers/var';
import {store} from "../../common/redux/store";

// Assuming you have a function to retrieve the JWT token
const GetAuthToken = () => {
    // Your logic to retrieve the JWT token (from localStorage, cookies, etc.)
    return store.getState().Login.user["token"];
  };

const ApiService = axios.create({
  baseURL: _var.base_api_url,
  // Add custom configurations if needed
});
const ServiceWithoutAuthentication = axios.create({
  baseURL: _var.base_api_url,
  // Add custom configurations if needed
});
// Interceptor to add JWT token to headers for every request
ApiService.interceptors.request.use(
    (config) => {
      const authToken = GetAuthToken();
      if (authToken || authToken != "") {
        config.headers['Authorization'] = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

const ServiceInstance = {
  // Example: Get data from an endpoint
  FetchData: async (path:string = "", id:string = "") => {
    try {
      const response = await ApiService.get(`${path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // Example: Post data to an endpoint
  PostData: async (path:string = "", data:any = "") => {
    try {
      const response = await ApiService.post(`${path}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  Login: async (path:string = "", data:any = "") => {
    try {
      const response = await ServiceWithoutAuthentication.post(`/api/v1/identity${path}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // Add more functions for other endpoints as needed
};

export default ServiceInstance;