import { getFirebaseBackend } from "helpers/firebase_helper";
import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import  ServiceInstance  from "../../../common/services/api_service";

export const loginuser = (user: any) => async (dispatch: any) => {
    try {
        let response:any = await ServiceInstance.Login('/login', {email: user.email, password: user.password});
        dispatch(loginSuccess(response));
    } catch (error:any) {
        dispatch(apiError(logoutUserSuccess(error.response.data.errors[0])));
    }
}

export const logoutUser = () => async (dispatch: any) => {
    try {
        dispatch(logoutUserSuccess(true));
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};


export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);
        }

        const socialdata = await response;
        if (socialdata) {
            sessionStorage.setItem("authUser", JSON.stringify(socialdata));
            dispatch(loginSuccess(socialdata));
            history('/dashboard');
        }

    } catch (error) {
        dispatch(apiError(error));
    }
};