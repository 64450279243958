import { jwtDecode } from 'jwt-decode';
import { store } from '../common/redux/store';
type FilterFunction<T> = (item: T) => boolean;

interface ClaimData {
  Role: any;
  RoleClaims: any;
  Claims: any;
  CurrentTenantId: string;
  CurrentUserId: string;
}

export const utils = {
  // Example: Get data from an endpoint
  DecodeToken: () => {
    const authObj: any = (store.getState())?.Login;
    const decodeObj: ClaimData = jwtDecode(authObj?.user?.token ?? "");

    decodeObj.Claims = JSON.parse(decodeObj?.Claims ?? "");
    decodeObj.RoleClaims = JSON.parse(decodeObj?.RoleClaims ?? "");
    decodeObj.Role = JSON.parse(decodeObj?.Role ?? "");

    return decodeObj;
  },
  FilterArray<T>(array: T[], filterFn: FilterFunction<T>): T[] {
    return array.filter(filterFn);
  },
  FetchUserClaimData: (key:string) => {
    var claimObj: any = {};
    claimObj = utils.DecodeToken();
    claimObj = utils.FilterArray(claimObj.Claims, (x: any) => x.ClaimType ===key)[0];
    claimObj = claimObj.ClaimValue

    return claimObj;
  },
  FetchRoleClaimData: (key:string) => {
    var claimObj: any = {};
    claimObj = utils.DecodeToken();
    claimObj = utils.FilterArray(claimObj.RoleClaims, (x: any) => x.ClaimType ===key)[0];
    claimObj = claimObj.ClaimValue

    return claimObj;
  },
  FetchRoleData: (key:string) => {
    var claimObj: any = {};
    claimObj = utils.DecodeToken();
    claimObj = utils.FilterArray(claimObj.Role, (x: any) => x.ClaimType ===key)[0];
    claimObj = claimObj.ClaimValue

    return claimObj;
  },
  BeginningOfYear : () => {
   return new Date((new Date()).getFullYear(), 0, 1).toDateString();
  },
  ExtractDecimalFromString: (input: string) => {
    // Remove dollar signs and commas from the string
    const cleanedString = input.replace(/[,$]/g, '');
  
    // Parse the cleaned string to a floating-point number
    const parsedNumber = parseFloat(cleanedString);
  
    // Check if the parsed number is a valid finite number
    if (!isNaN(parsedNumber) && isFinite(parsedNumber)) {
      return parsedNumber;
    } else {
      // Return null if the parsing is unsuccessful
      return 0.0;
    }
  }
};
