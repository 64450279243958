import { combineReducers } from 'redux';
import domainReducer from './domain_reducer';
 
import AuthReducer from './auth_reducer';
import SysReducer from './sys_reducer';
import GlobalReducer from './global_reducer';
import AppReducer from './app_reducer';

// Front
// import LayoutReducer from "./layouts/reducer";
import EcommerenceReducer from '../../../slices/e-commerence/reducer';
import CryptoReducer from "../../../slices/crypto/reducer";
import InvoiceReducer from "../../../slices/invoices/reducer";
import ProjectsReducer from "../../../slices/projects/reducer";
import TasksReducer from "../../../slices/tasks/reducer";
import ContactsReducer from "../../../slices/contacts/reducer"
import DashboardReducer from "../../../slices/dashboards/reducer";
import JobsReducer from "../../../slices/jobs/reducer";
import CalendarReducer from "../../../slices/calendar/reducer";
import LayoutReducer from "../../../slices/layouts/reducer";
import EmailReducer from "../../../slices/email/reducer";
import LoginReducer from "../../../slices/auth/login/reducer";
import ProfileReducer from "../../../slices/auth/profile/reducer";
import ForgotPasswordReducer from "../../../slices/auth/forgetpwd/reducer";
import AccountReducer from "../../../slices/auth/register/reducer";

export const RootReducer = combineReducers({
    sys: SysReducer,
    app: AppReducer,
    global: GlobalReducer,
    domain: domainReducer,
    auth: AuthReducer,
    Layout: LayoutReducer,
    ecommerce: EcommerenceReducer,
    crypto: CryptoReducer,
    invoices: InvoiceReducer,
    projects: ProjectsReducer,
    tasks: TasksReducer,
    contacts: ContactsReducer,
    dashboard: DashboardReducer,
    jobs: JobsReducer,
    calendar: CalendarReducer,
    email: EmailReducer,
    Login: LoginReducer,
    Profile: ProfileReducer,
    ForgetPassword: ForgotPasswordReducer,
    Account: AccountReducer
});
