import ServiceInstance from "../../services/api_service";
import { GetUserRentalRevenueSuccess, GetOwnerRentalProfitLossSuccess, 
  GetOwnerNetIncomeDetailsSuccess, GetPropertyIncomeSummarySuccess, Error } from "../reducers/app_reducer";
import _var from "../../../helpers/var";

export const GetUserRentalRevenue = (tenant:string, user:string, fromdate:string, todate:string) => async (dispatch: any) => {
  try {
    let response: any = await ServiceInstance
      .FetchData(`${_var.main_api_url_v2}/account/history/summary/${tenant}/${user}/${fromdate}/${todate}`);
    dispatch(GetUserRentalRevenueSuccess(response));
  } catch (error: any) {
    dispatch(Error("error"));
  }
 
}
export const GetOwnerRentalProfitLoss = (tenant:string, user:string, fromdate:string, todate:string) => async (dispatch: any) => {
  try {
    let response: any = await ServiceInstance
      .FetchData(`${_var.main_api_url_v2}/account/history/summary/profitloss/${tenant}/${user}/${fromdate}/${todate}`);
    dispatch(GetOwnerRentalProfitLossSuccess(response));
  } catch (error: any) {
    dispatch(Error("error"));
  }
}

export const GetOwnerNetIncomeDetails = (tenant:string, user:string, fromdate:string, todate:string) => async (dispatch: any) => {
  try {
    let response: any = await ServiceInstance
      .FetchData(`${_var.main_api_url_v2}/account/history/detail/netincome/${tenant}/${user}/${fromdate}/${todate}`);
    dispatch(GetOwnerNetIncomeDetailsSuccess(response));
  } catch (error: any) {
    dispatch(Error("error"));
  }
}

export const GetPropertyIncomeSummary = (tenant:string, user:string, fromdate:string, todate:string) => async (dispatch: any) => {
  try {
    let response: any = await ServiceInstance
      .FetchData(`${_var.main_api_url_v2}/account/history/summary/propertyincome/${tenant}/${user}/${fromdate}/${todate}`);
    dispatch(GetPropertyIncomeSummarySuccess(response));
  } catch (error: any) {
    dispatch(Error("error"));
  }
}